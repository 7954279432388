import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'

export const PrivacyTemplate = ({ content }) => {
  return (
    <section className="section visie page section--gradient">
      <div className="container">
        <div className="columns is-centered is-mobile">
          <div className="column is-four-fifths-mobile is-full-tablet is-two-thirds-desktop">
            <div className="columns visie-content">
              <div className="column is-12">
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

PrivacyTemplate.propTypes = {
  content: PropTypes.string,
  quote: PropTypes.string,
  img: PropTypes.object,
}

const Privacy = ({ data }) => {
  const { wordpressPage: page } = data
  const { site } = data
  const { title: siteTitle } = site.siteMetadata
  const { meta_description: metadesc, meta_tags: metatags } = page.acf

  return (
    <Layout>
      <Helmet>
        <title>{`${page.title} | ${siteTitle}`}</title>
        {metadesc ? <meta name="description" content={metadesc} /> : null}
        {metatags ? <meta name="keywords" content={metatags} /> : null}
      </Helmet>
      <PrivacyTemplate content={page.content} />
    </Layout>
  )
}

Privacy.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Privacy

export const pageQuery = graphql`
  query PrivacyByID {
    site {
      siteMetadata {
        title
      }
    }
    wordpressPage(wordpress_id: { eq: 143 }) {
      title
      content

      acf {
        meta_description
        meta_tags
      }
    }
  }
`
